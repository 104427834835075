export function generateDots(count) {
  let textShadow = [];
  for (let i = 0; i < count; i++) {
    const x = (-0.5 + Math.random() * 3).toFixed(2) + 'em';
    const y = (-0.5 + Math.random() * 1).toFixed(2) + 'em';
    const blur = '10px';
    const color = `hsla(${Math.floor(Math.random() * 360)}, 100%, 60%, 1)`;
    textShadow.push(`${x} ${y} ${blur} ${color}`);
  }
  return textShadow.join(', ');
}