import React from 'react';

/*gsap anim*/
import { gsap} from "gsap";
import './dynamicDots.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { generateDots } from './dynamicDots';
import Xlogo from './images/unrevealed-white.svg';
import snixlogo from './images/snixLogoWhite.svg';
gsap.registerPlugin(ScrollTrigger);
const { useLayoutEffect } = React;
/*gsap anim*/

function Home() {

  useLayoutEffect(() => {
    const dots = generateDots(200);
    document.documentElement.style.setProperty('--dots', dots);
 }, []);



  return (
    <>
    <div className='containerFullHeight bg-black d-flex flex-column align-items-center justify-content-center'>
    <img className='img-fluid unreLogo mb-4' src={Xlogo} alt='SnixXLogo' />
    <img className='img-fluid snixLogo' src={snixlogo} alt='SnixLogo' />
      <div className="animated-dots">
      
      </div>
    </div>
  
    </>
)
}

export default Home
