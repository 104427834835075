import React from 'react'
import { useState } from 'react';
import { gsap } from "gsap";
import { Link } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from './images/LogoWhite.svg';
import Twitter from './images/x-logo.svg';


gsap.registerPlugin(ScrollTrigger);
const { useLayoutEffect, useRef } = React;



function NavBar() {

  const [expanded, setExpanded] = useState(false);



  const navbox = useRef();
  useLayoutEffect(() => {

    const showAnim = gsap.from(navbox.current , { 
      yPercent: -100,
      paused: true,
      duration: 0.3
    }).progress(1);
   
    ScrollTrigger.create({
      start: "100px top",
      end: 99999,
      onUpdate: (self) =>  {
        self.direction === -1 ? showAnim.play() : showAnim.reverse() }
      
    });
    ScrollTrigger.create({
      start: '100px top',
      end: 99999,
      toggleClass: {className: 'scrolled', targets: '.main-navbar'}
    });

    return () => showAnim.revert();
  }, []);


 /* useLayoutEffect(() => {
  const navColor = gsap.from(navbox.current , { 
    background: "#ffffff00",
    duration: 0.2
  });

  ScrollTrigger.create({
    animation: navColor,
    start: "top 10px",
    end: "+=10000" , 
    scrub: true,
  });
 return () => navColor.revert(); 
});*/



  return (
    <>
     <Navbar expanded={expanded} expand="lg" ref={navbox} className='main-navbar'>
    <Container className='p-0 px-lg-3 py-lg-3'>
      <Navbar.Brand className='d-none d-lg-inline'><Link className='navbar-brand' to="/">
    
      <img className='img-fluid logoNav' src={Logo} alt='Logo' />
        </Link></Navbar.Brand>
     
      <bottom className="mobileMenu d-lg-none py-3" onClick={() => setExpanded(!expanded)}>MENU</bottom>
      <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
        <Nav>
        <Nav.Link href="https://x.com/NullHzn" target="_blank" rel="noreferrer" className="nav-link text-center text-lg-start">
            <img className="img-fluid iconsNav" src={Twitter} alt="X" />
        </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  </>
  )
}

export default NavBar
