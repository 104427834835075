import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home.js';
import NavBar from './NavBar.js';
import Footer from './Footer.js';
import {Routes, Route} from 'react-router-dom';
import './index.css';

function App() {
  return (
    <>
    <NavBar />
    <Routes>
      <Route exact path="/" element={<Home />} />
    </Routes>
    <Footer />
    </>
  )
}

export default App;
