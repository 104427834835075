import React from 'react';


function Footer() {


  return (
    <>
     <div className='footerContainer bg-black'>
    <div className='container-fluid py-4' >
      <div className='row justify-content-center px-5 '>
        <div className='col-auto'>
        <p className=' text-center m-0 text-white'>© 2024 Snix by Null Horizon</p>
        </div>
      </div>
    </div>
    </div>
  
  </>
  )
}

export default Footer
